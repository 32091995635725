import React, { useCallback, Suspense } from 'react';
import { useDropzone } from 'react-dropzone';
import XLSX from 'xlsx';

import { useTranslation, Trans } from 'react-i18next';

import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { green, yellow } from '@mui/material/colors';
import { Global, css } from '@emotion/react'

import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PrintIcon from '@mui/icons-material/Print';
import { countries, ueFlagPng, pageOrientations } from './utils';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import merge from 'deepmerge';

import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';

import standardPageSizes from "pdfmake/src/standardPageSizes";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import ReactGA from 'react-ga4';
import { Typography } from '@mui/material';

ReactGA.initialize('G-VYBBNFVGN4');
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const lngs = {
  de: {
    nativeName: 'Deutsch'
  },
  en: {
    nativeName: 'English'
  },
  es: {
    nativeName: 'Español'
  },
  fr: {
    nativeName: 'Français'
  },
  it: {
    nativeName: 'Italiano'
  },
  nl: {
    nativeName: 'Nederlands'
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: green[600]
    },
    secondary: {
      main: yellow[800]
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  },
});

const Root = styled('div')`
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  padding: ${theme.spacing(2)};
`;
const Title = styled('div')`
  display: flex;
  text-align: center;
  align-items: center;
  padding-top: ${theme.spacing(2)};
  flex-direction: column;
  padding-bottom: ${theme.spacing(3)};
`;
const Header2 = styled('h2')`
  line-height: 2em;
  &:hover .MuiLink-root {
    display: inline-block;
  }
  .MuiLink-root {
    display: none;
    color: #8a8a8a;
    padding-left: ${theme.spacing(1)};
  }
  a:first-of-type {
    position: absolute;
    visibility: hidden;
    margin-top: -${theme.spacing(1)};
  }
`;
const Header3 = Header2.withComponent('h3');
const Preview = styled('div')`
  font-size: 0.7em;
  overflow-x: scroll;
  background-color: #eee;
  & > TABLE {
    width: 100%;
    & > TBODY {
      & > TR:nth-of-type(even) {
        background-color: #fcfcfc;
      },
      & > TR:nth-of-type(odd) {
        background-color: #f6f6f6;
      },
      & > TR:first-of-type {
        background-color: #f1f1f1;
        font-weight: bold;
      },
      & > TR:nth-of-type(5) {
        color: #ccc;
      }
    }
  }
`;
const MyPaper = styled(Paper)`
  padding: ${theme.spacing(2)};
`;
const Footer = styled('div')`
  padding-top: ${theme.spacing(5)};
  text-align: right;
  font-style: italic;
  color: #8a8a8a;
`;

function App() {

  //i18n

  const { t, i18n } = useTranslation();

  const changeLanguage = (nextLanguage) => {
    let url = '/';
    if (nextLanguage !== 'en') {
      url += nextLanguage;
    }
    const routes = i18n.getResourceBundle(i18n.language, 'routes');
    const hash = window.location.hash.replace(/#+/, '');
    const route = Object.keys(routes).find((key) => routes[key] === hash);
    if (route === undefined) {
      window.location.replace(url);
    }
    else {
      i18n
      .loadLanguages([nextLanguage])
      .then(() => {
        url += '#' + i18n.getResource(nextLanguage, 'routes', route);
        window.location.replace(url);
      });
    }
  };

  //
  
  React.useEffect(() => {
  });
  
  //DATA

  const cookieName = 'ppp-data';
  const [cookies, setCookie] = useCookies([cookieName]);
  
  let currentData = {
    operator: {
      country: 'FR',
      number: '',
      pz: false,
      pzName: null
    },
    print: {
      page: {
        format: 'A4',
        orientation: 'portrait',
        customWidth: 595.28,
        customHeight: 841.89
      },
      margin: {
        active: true,
        left: 20,
        top: 40,
        right: 20,
        bottom: 40
      },
      grid: {
        cols: 3,
        rows: 7,
        colGap: 8,
        rowGap: 2,
        itemPadding: 8
      }
    }
  };
  if (cookies && cookies[cookieName]) {
    currentData = merge(currentData, cookies[cookieName]);
  }
  if (!(typeof currentData.operator.country === 'string' || currentData.operator.country instanceof String)) {
    currentData.operator.country = 'FR';
  }

  //FILE
  
  const [xlsxPreview, setXlsxPreview] = React.useState(null);
  const [columns, setColumns] = React.useState([]);
  
  const [worksheet, setWorksheet] = React.useState(null);

  const [currentColumns, setCurrentColumns] = React.useState(currentData.columns);

  const onDrop = useCallback((acceptedFiles) => {
    const readFile = function(file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var cols = [];
        var data = new Uint8Array(e.target.result);
        var wb = XLSX.read(data, {type: 'array'});
        var first_sheet_name = wb.SheetNames[0];
        var ws = wb.Sheets[first_sheet_name];
        var ar = [];
        var row, r, c;
        var range = XLSX.utils.decode_range(ws['!ref']);
        var rowcount = range.e.r - range.s.r;
        var rowplus = -1;
        if (rowcount > 0) {
          if (rowcount > 6) {
            rowplus = rowcount - 5;
          }
          for (r = range.s.r; r <= range.e.r; r++) {
            row = [];
            for (c = range.s.c; c <= range.e.c; c++) {
              var cell = ws[XLSX.utils.encode_cell({r: r, c: c})];
              if (typeof cell === 'undefined') {
                row.push(void 0);
              } else row.push(cell.w || cell.v);
            }
            ar.push(row);
            if (r === range.s.r) {
              cols = [].concat(row);
              for (c = row.length - 1; c >= 0; c--) {
                if (cols[c] === undefined) {
                  cols.pop();
                }
                else break;
              }
              cols.unshift('');
            } else if (rowplus >= 0 && r === range.s.r + 3) {
              row = [];
              for (c = range.s.c; c <= range.e.c; c++) {
                row.push('-');
              }
              ar.push(row);
              r = r + rowplus;
              rowplus = -1;
            }
          }
        }
        setXlsxPreview({__html: XLSX.utils.sheet_to_html(XLSX.utils.aoa_to_sheet(ar), { header: '', footer: '' })});

        //
        const cs = (cols.length > 0) ?
          cols.reduce((acc, cur, index) => {
            if (cur && Object.keys(acc).indexOf(cur) < 0) {
              acc[cur] = {
                label: cur,
                index: index
              };
            }
            return acc;
          }, {}) : {};
        setColumns(cs);
        
        //
        const _theCols = initCols();
        const csk = Object.keys(cs);
        const csklen = csk.length;
        if (csklen > 0 && currentColumns) {
          Object.keys(currentColumns).forEach((ck) => {
            let i = 0;
            for (; i < csklen; i++) {
              if (csk[i] === currentColumns[ck]) {
                _theCols[ck] = i;
                break;
              }
            }
          });
        }
        setTheCols(_theCols);

        //
        setWorksheet(ws);
      };
      reader.readAsArrayBuffer(file);
    };
    if (acceptedFiles && acceptedFiles.length > 0) {
      readFile(acceptedFiles[0]);
      ReactGA.event({
        category: 'Usage',
        action: 'open a file'
      });
    };
  }, [currentColumns]);
  const {getRootProps, getInputProps} = useDropzone({onDrop});

  const initCols = () => ({
    bn: -1,
    tc: -1,
    orc: -1,
    orn: -1,
    qty: -1
  });
  const [theCols, setTheCols] = React.useState(initCols());
  const handleColumnChange = (column, event) => {
    event.preventDefault();
    const cols = {...theCols};
    cols[column] = parseInt(event.target.value);
    setTheCols(cols);
  };

  const handleProcessFile = (event) => {
    event.preventDefault();
    const _cks = Object.keys(columns);
    const _cols = [];
    Object.keys(theCols).forEach((k) => {
      if (theCols[k] >= 0) {
        _cols.push({ key: k, value: columns[_cks[theCols[k]]].label });
      }
    });
    const ar = XLSX.utils.sheet_to_json(worksheet);
    const _items = [];
    let _key = key + 1;
    ar.forEach((item) => {
      const _item = { key: _key++ };
      _cols.forEach((c) => {
        _item[c.key] = c.key !== 'orc' ? item[c.value] : countries.find(co => co.code === item[c.value]);
      });
      _items.push(_item);
    });
    setKey(_key);
    setItems(_items);
    ReactGA.event({
      category: 'Usage',
      action: 'update list with file'
    });
  };
  
  //FORM
  
  const [pz, setPz] = React.useState(currentData.operator.pz);
  const [opc, setOpc] = React.useState(countries.find(item => item.code === currentData.operator.country));
  
  const getSpsVal = function(format) {
    return format !== 'Custom' ? format : null;
  };
  const [spsVal, setSpsVal] = React.useState(getSpsVal(currentData.print.page.format));
  const handlePageSizeChange = (value) => {
    setSpsVal(getSpsVal(value));
  };

  const [poriVal, setPoriVal] = React.useState(currentData.print.page.orientation);

  const [key, setKey] = React.useState(0);
  const [items, setItems] = React.useState([]);

  const handleNewItem = (event) => {
    event.preventDefault();
    const _items = [...items];
    const _key = key + 1;
    setKey(_key);
    _items.push({ key: _key });
    setItems(_items);
  };

  const handleDeleteItem = (event, index) => {
    event.preventDefault();
    const _items = [...items];
    _items.splice(index, 1);
    setItems(_items);
  };

  let timeoutBn = null;
  const changeBn = (index, value, reason) => {
    if (reason !== "reset" && reason !== "clear") {
      if (timeoutBn) clearTimeout(timeoutBn);
      timeoutBn = setTimeout(() => {
        const uri = "https://www.ipni.org/api/1/suggest?query=" + encodeURIComponent(value);
        fetch(uri)
          .then(res => res.json())
          .then(result => {
            const _items = [...items];
            _items[index].optionsBn = result.suggestedTerms["scientific-name"];
            setItems(_items);
          }
        );
      }, 400);
    }
  };

  //PRINT

  const [margin, setMargin] = React.useState(currentData.print.margin.active);

  const print = {
    docDefinition: {
      info: {
        title: 'EU plant passports - Powered by Print Plant Pass',
        creator: 'Print Plant Pass',
        producer: 'Print Plant Pass',
        subject: 'EU plant passports',
        keywords: 'plant passport print',
      },
      pageSize: 'A4',// a string or { width: number, height: number }
      /*
      '4A0', '2A0', 'A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10',
      'B0', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10',
      'C0', 'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10',
      'RA0', 'RA1', 'RA2', 'RA3', 'RA4',
      'SRA0', 'SRA1', 'SRA2', 'SRA3', 'SRA4',
      'EXECUTIVE', 'FOLIO', 'LEGAL', 'LETTER', 'TABLOID'
      */
      pageOrientation: currentData.print.page.orientation,//'portrait, 'landscape'
      pageMargins: [ currentData.print.margin.left, currentData.print.margin.top, currentData.print.margin.right, currentData.print.margin.bottom ],// [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      defaultStyle: {
        fontSize: 9
      },
      styles: {
        itemImage: {
          margin: 0
        },
        itemTitle: {
          margin: [3, 0, 0, 0],
          fontSize: 7.5
        },
        itemContent: {
          margin: [0, 3, 0, 0]
        }
      },
      images: {
        ueFlagPng: ueFlagPng
      }
    }
  };

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {

    console.log(data);

    const theData = {
      operator: {
        country: data.opc,
        number: data.opn,
        pz: pz,
        pzName: data.pzn
      },
      print: {
        page: {
          format: (spsVal === null) ? 'Custom' : spsVal,
          orientation: poriVal,
          customWidth: parseFloat(data.pswidth),
          customHeight: parseFloat(data.psheight)
        },
        margin: {
          active: margin,
          left: parseFloat(data.pmleft),
          top: parseFloat(data.pmtop),
          right: parseFloat(data.pmright),
          bottom: parseFloat(data.pmbottom)
        },
        grid: {
          cols: parseInt(data.rccols),
          rows: parseInt(data.rcrows),
          colGap: parseFloat(data.sphoriz),
          rowGap: parseFloat(data.spvertic),
          itemPadding: parseFloat(data.spinner)
        }
      }
    };
    
    let ps = [];
    let i, q, p;
    Object.keys(data).sort().forEach((k) => {
      if (k.startsWith('item')) {
        const index = k.indexOf('-', 5);
        if (index > 5) {
          const d = k.substring(index + 1);
          if (d === 'zty') {
            if (p) {
              q = (+data[k]);
              for (i = 0; i < q; i++) {
                ps.push(p);
              }
              p = null;
            }
          }
          else if (d === 'bn' || d === 'tc' || d === 'orc' || d === 'orn') {
            if (d === 'bn') p = {};
            p[d] = data[k];
          }
          else {
            p = null;
          }
        }
      }
    });

    const sep = '    ';
    const makeDetail = (item) => {
      let ar = [];
      ar.push({ text: 'A ' + item.bn, noWrap: true, italics: true });
      ar.push({ text: sep });
      ar.push({ text: 'B ' + theData.operator.country + ' - ' + theData.operator.number, noWrap: true });
      if (item.tc) {
        ar.push({ text: sep });
        ar.push({ text: 'C ' + item.tc, noWrap: true });
      }
      if (item.orc || item.orn) {
        ar.push({ text: sep });
        let d = '';
        if (item.orc) {
          d = item.orc;
        }
        if (item.orn) {
          if (item.orc) {
            d += ' ';
          }
          d += item.orn;
        }
        ar.push({ text: 'D ' + d, noWrap: true });
      }
      return ar;
    };

    const opc = countries.find(item => item.code === data.opc);
    let title = [{
      text: 'Plant Passport'
    }];
    if (theData.operator.pz) {
      title[0].text += ' - PZ';
    }
    if (opc.title) {
      let trad = opc.title;
      if (theData.operator.pz) {
        trad += ' - ' + (opc.pz || 'PZ');
      }
      title[0].text = trad + ' / ' + title[0].text;
    }
    if (theData.operator.pzName) {
      title.push({text: '\n' + theData.operator.pzName, italics: true});
    }

    const dd = {
      ...print.docDefinition,
      content: [
        {
          layout: 'gridLayout',
          table: {
            dontBreakRows: true,
            headerRows: 0,
            widths: [],
            heights: [],
            body: []
          }
        }
      ]
    };
    dd.pageSize = (spsVal === null) ? { width: theData.print.page.customWidth, height: theData.print.page.customHeight } : spsVal;
    dd.pageOrientation = theData.print.page.orientation;
    dd.pageMargins = [ theData.print.margin.left, theData.print.margin.top, theData.print.margin.right, theData.print.margin.bottom ];
    dd.styles.cellFirst = { margin: [ theData.print.grid.itemPadding, theData.print.grid.itemPadding, (theData.print.grid.colGap/2) + theData.print.grid.itemPadding, theData.print.grid.rowGap + theData.print.grid.itemPadding ] };
    dd.styles.cellMid = { margin: [ (theData.print.grid.colGap/2) + theData.print.grid.itemPadding, theData.print.grid.itemPadding, (theData.print.grid.colGap/2) + theData.print.grid.itemPadding, theData.print.grid.rowGap + theData.print.grid.itemPadding ] };
    dd.styles.cellLast = { margin: [ (theData.print.grid.colGap/2) + theData.print.grid.itemPadding, theData.print.grid.itemPadding, theData.print.grid.itemPadding, theData.print.grid.rowGap + theData.print.grid.itemPadding ] };

    // Col widths
    for (i = 0; i < theData.print.grid.cols; i++) {
      dd.content[0].table.widths.push('*');
    }

    // Row heights
    const pslen = ps.length;
    const ah = (spsVal === null ? [dd.pageSize.width, dd.pageSize.height] : standardPageSizes[dd.pageSize])[(theData.print.page.orientation === 'portrait') ? 1 : 0] - dd.pageMargins[1] - dd.pageMargins[3];
    const rh = (ah / theData.print.grid.rows) - 0.01;
    const rn = Math.ceil(pslen / theData.print.grid.cols);
    for (i = 0; i < rn; i++) {
      dd.content[0].table.heights.push(rh);
    }

    // Doc definition
    let row = -1;
    for (i = 0; i < pslen; i++) {
      if ((i % theData.print.grid.cols) === 0) {
        dd.content[0].table.body.push([]);
        row++;
      }
      dd.content[0].table.body[row].push({
        layout: 'itemLayout',
        style: 'cell' + ((i % theData.print.grid.cols) === 0 ? 'First' : ((i % theData.print.grid.cols) === theData.print.grid.cols - 1 ? 'Last' : 'Mid')),
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: [40, '*'],
          body: [
            [
              {
                image: 'ueFlagPng',
                width: 40,
                style: 'itemImage'
              },
              {
                text: title,
                style: 'itemTitle'
              }
            ],
            [
              {
                colSpan: 2,
                text: makeDetail(ps[i]),
                style: 'itemContent'
              }
            ]
          ]
        },
      });
    }
    if (row >= 0) {
      for (; (i % theData.print.grid.cols) > 0; i++) {
        dd.content[0].table.body[row].push({});
      }
    }

    // Table layouts
    const borderWidth = 0;
    const tableLayouts = {
      gridLayout: {
        hLineWidth: function () { return borderWidth; },
        vLineWidth: function () { return borderWidth; },
        paddingLeft: function() { return 0; },
        paddingTop: function() { return 0; },
        paddingRight: function() { return 0; },
        paddingBottom: function() { return 0; }
      },
      itemLayout: {
        hLineWidth: function () { return borderWidth; },
        vLineWidth: function () { return borderWidth; },
        paddingLeft: function() { return 0; },
        paddingTop: function() { return 0; },
        paddingRight: function() { return 0; },
        paddingBottom: function() { return 0; }
      }
    };

    // Download
    pdfMake.createPdf(dd, tableLayouts).download("PrintPlantPass.pdf");

    // Column mapping
    theData.columns = {};
    const _cks = Object.keys(columns);
    Object.keys(theCols).forEach((k) => {
      if (theCols[k] >= 0) {
        theData.columns[k] = columns[_cks[theCols[k]]].label;
      }
    });
    setCurrentColumns(theData.columns);

    // Save to cookie
    setCookie(cookieName, theData, { path: '/' });

    ReactGA.event({
      category: 'Usage',
      action: 'make pdf'
    });
  };

  return (
    <Suspense fallback="loading">
    <ThemeProvider theme={theme}>
    <Root>
      <Global
        styles={css`
          .drop-zone {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            border-width: 2;
            border-radius: 2;
            border-color: #eeeeee;
            border-style: dashed;
            background-color: #fafafa;
            color: #8a8a8a;
            outline: none;
            transition: border .24s ease-in-out;
          }
          .bold {
            font-weight: bold;
          }
          .MuiAutocomplete-popper {
            min-width: 200px;
          }
          .MuiAutocomplete-option {
            font-size: 15px;
            & > span {
              margin-right: 10px;
              font-size: 18px;
            }
          }
        `}
      />
      <Select sx={{ position: 'absolute', top: 0, right: 0, m: 2 }}
        size="small"
        value= {i18n.language}
        onChange={(event) => changeLanguage(event.target.value)}
        displayEmpty
        inputProps={{ 'aria-label': t('changeLanguage') }}
      >
        {Object.keys(lngs).map((lng) => (
          <MenuItem key={lng} value={lng}>{lngs[lng].nativeName}</MenuItem>
        ))}
      </Select>
      <Title>
        <img src="/android-chrome-192x192.png" alt="Print Plant Pass logo" />
        <div>
          <h1>Print&nbsp;Plant&nbsp;Pass</h1>
          <h5>{t('service')}</h5>
          <h3><Link sx={{ mt: 2 }} color="primary" href={`#${t('routes:print')}`} aria-label={t('sloganAria')}>{t('slogan')}</Link></h3>
        </div>
      </Title>
      <Header2>
        <a href="/#" id={t('routes:what')}>&nbsp;</a>
        {t('what')}
        <Link aria-label={t('whatAria')} aria-hidden="true" href={`#${t('routes:what')}`}><LinkIcon /></Link>
      </Header2>
      <p><Trans i18nKey="whatText"></Trans></p>
      <Header2>
        <a href="/#" id={t('routes:who')}>&nbsp;</a>
        <Trans i18nKey="who"></Trans>
        <Link aria-label={t('whoAria')} aria-hidden="true" href={`#${t('routes:who')}`}><LinkIcon /></Link>
      </Header2>
      <p><Trans i18nKey="whoText"></Trans></p>
      <Header2>
        <a href="/#" id={t('routes:print')}>&nbsp;</a>
        {t('print')}
        <Link aria-label={t('printAria')} aria-hidden="true" href={`#${t('routes:print')}`}><LinkIcon /></Link>
      </Header2>
      <p><Trans i18nKey="printText"></Trans></p>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Header3>
          <a href="/#" id={t('routes:operator')}>&nbsp;</a>
          1)&nbsp;{t('operator')}
          <Link aria-label={t('operatorAria')} aria-hidden="true" href={`#${t('routes:operator')}`}><LinkIcon /></Link>
        </Header3>
        <Trans i18nKey="operatorText"><p>It is made up of the country code and your registration number with the <Link aria-label={t('authorityAria')} aria-hidden="true" href={`#${t('routes:authority')}`}>competent authorities</Link>.</p></Trans>
        <MyPaper elevation={1}>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={2}>
              <Autocomplete
                options={countries.filter((item) => item.eu === true)}
                fullWidth
                openOnFocus
                disableClearable
                getOptionLabel={(option) => option.code}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <span>{option.code}</span>&nbsp;{option.label}
                  </Box>
                )}
                value={opc}
                onChange={(event, value) => setOpc(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register("opc", { value: currentData.operator.country, required: true })}
                    placeholder={t('operatorCountryPlaceholder')}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      'aria-label': t('operatorCountryAria')
                    }} />
                )}
              />
            </Grid>
            <Grid item xs={8} sm={10}>
              <TextField
                {...register("opn", { value: currentData.operator.number, required: true })}
                placeholder={t('operatorNumberPlaceholder')}
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('operatorNumberAria') }}
                fullWidth />
            </Grid>
          </Grid>
        </MyPaper>
        <Header3>
          <a href="/#" id={t('routes:list')}>&nbsp;</a>
          2)&nbsp;{t('list')}
          <Link aria-label={t('listAria')} aria-hidden="true" href={`#${t('routes:list')}`}><LinkIcon /></Link>
        </Header3>
        <p><Trans i18nKey="listText"></Trans></p>
        <MyPaper elevation={1}>
          <p><Trans i18nKey="listFile"></Trans></p>
          <Box {...getRootProps({className: "drop-zone"})} sx={xlsxPreview ? { borderBottom: 0 } : {}}>
            <input {...getInputProps()} />
            <p><Trans i18nKey="listFileDrop"></Trans></p>
          </Box>
          {xlsxPreview &&
          <React.Fragment>
            <Preview dangerouslySetInnerHTML={xlsxPreview}></Preview>
            <p><Trans i18nKey="listColumns"></Trans></p>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    select
                    label={t('bnCol')}
                    inputProps={{ 'aria-label': t('bnColAria') }}
                    value={theCols.bn}
                    onChange={(event) => handleColumnChange('bn', event)}
                    fullWidth>
                    <MenuItem value={-1}>...</MenuItem>
                    {Object.keys(columns).map((item, index) =>
                      <MenuItem key={index} value={index}>
                        {columns[item].label}
                      </MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    label={t('tcCol')}
                    inputProps={{ 'aria-label': t('tcColAria') }}
                    value={theCols.tc}
                    onChange={(event) => handleColumnChange('tc', event)}
                    fullWidth>
                    <MenuItem value={-1}>...</MenuItem>
                    {Object.keys(columns).map((item, index) =>
                      <MenuItem key={index} value={index}>
                        {columns[item].label}
                      </MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    label={t('orcCol')}
                    inputProps={{ 'aria-label': t('orcColAria') }}
                    value={theCols.orc}
                    onChange={(event) => handleColumnChange('orc', event)}
                    fullWidth>
                    <MenuItem value={-1}>...</MenuItem>
                    {Object.keys(columns).map((item, index) =>
                      <MenuItem key={index} value={index}>
                        {columns[item].label}
                      </MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    label={t('ornCol')}
                    inputProps={{ 'aria-label': t('ornColAria') }}
                    value={theCols.orn}
                    onChange={(event) => handleColumnChange('orn', event)}
                    fullWidth>
                    <MenuItem value={-1}>...</MenuItem>
                    {Object.keys(columns).map((item, index) =>
                      <MenuItem key={index} value={index}>
                        {columns[item].label}
                      </MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    label={t('qtyCol')}
                    inputProps={{ 'aria-label': t('qtyColAria') }}
                    value={theCols.qty}
                    onChange={(event) => handleColumnChange('qty', event)}
                    fullWidth>
                    <MenuItem value={-1}>...</MenuItem>
                    {Object.keys(columns).map((item, index) =>
                      <MenuItem key={index} value={index}>
                        {columns[item].label}
                      </MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button sx={{ height: '100%' }} variant="contained" size="small" disableElevation color="secondary" disabled={theCols.bn < 0} endIcon={<PlaylistAddIcon />} onClick={(event) => handleProcessFile(event)} aria-label={t('fillAria')} css={`display: inline-flex; height: 100%;`}>
                    {t('fill')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
          }
          <p><Trans i18nKey="items"></Trans>
            <IconButton onClick={(event) => handleNewItem(event)} color="primary" size="small" aria-label={t('itemsAria')} css={{marginLeft: theme.spacing(2)}}>
              <AddCircleIcon />
            </IconButton>
          </p>
          <Divider sx={{ my: 2 }} />
          {items.map((item, index) =>
            <Grid key={item.key} container spacing={1}>
              <Grid item xs={3}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  freeSolo={true}
                  options={item.optionsBn || []}
                  filterOptions={(x) => x}
                  onInputChange={(event, value, reason) => changeBn(index, value, reason)}
                  noOptionsText={t('typePlant')}
                  value={item.bn}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...register(`item-${item.key}-bn`, { value: item.bn, required: true })}
                      placeholder={t('bn')}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                        'aria-label': t('bnAria')
                      }}
                      fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={2} sm={3}>
                <Input
                  placeholder={t('tc')}
                  inputProps={{ autoComplete: 'new-password', 'aria-label': t('tcAria') }}
                  fullWidth
                  {...register(`item-${item.key}-tc`, { value: item.tc })} />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={countries}
                  fullWidth
                  openOnFocus
                  getOptionLabel={(option) => option.code}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <span className={option.eu ? "bold" : null}>{option.code}</span>
                      {option.label}
                    </Box>
                  )}
                  forcePopupIcon={false}
                  value={item.orc}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...register(`item-${item.key}-orc`)}
                      placeholder={t('orc')}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                        'aria-label': t('orcAria')
                      }} />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  placeholder={t('orn')}
                  inputProps={{ autoComplete: 'new-password', 'aria-label': t('ornAria') }}
                  fullWidth
                  {...register(`item-${item.key}-orn`, { value: item.orn })} />
              </Grid>
              <Grid item xs={2} sm={1}>
                <Input
                  type="number"
                  inputProps={{ autoComplete: 'new-password', 'aria-label': t('qtyAria') }}
                  placeholder={t('qty')}
                  fullWidth
                  {...register(`item-${item.key}-zty`, { value: item.qty, required: true, min: 1, max: 10000 })} />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={(event) => handleDeleteItem(event, index)} color="primary" size="small" aria-label="delete item">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {items.length <= 0 &&
            <Box sx={{ fontStyle: 'italic' }}>{t('noPlant')}</Box>
          }
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mx: 0, my: 2 }}>
            <FormControlLabel
              control={<Checkbox color="primary" name="pz" checked={pz} onChange={(event) => setPz(event.target.checked)} />}
              label={t('pz')}
              labelPlacement="start"
              sx={{ marginLeft: 0 }}
              aria-label={t('pzAria')}
            />
            {pz &&
              <TextField
                {...register("pzn", { value: currentData.operator.pzName, required: true })}
                placeholder={t('pzPlaceholder')}
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('pzAria2') }}
                fullWidth />
            }
          </Box>
        </MyPaper>
        <Header3>
          <a href="/#" id={t('routes:calibrate')}>&nbsp;</a>
          3)&nbsp;{t('calibrate')}
          <Link aria-label={t('calibrateAria')} aria-hidden="true" href={`#${t('routes:calibrate')}`}><LinkIcon /></Link>
        </Header3>
        <p><Trans i18nKey="calibrateText"></Trans></p>
        <MyPaper elevation={1}>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={3}>
              <p>{t('page')}</p>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                {...register("sps")}
                defaultValue={currentData.print.page.format}
                label={t('format')}
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('formatAria') }}
                fullWidth
                onChange={(event) => handlePageSizeChange(event.target.value)}>
                {Object.keys(standardPageSizes).map((key) =>
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                )}
                <MenuItem key={0} value='Custom'>
                  {t('custom')}...
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                {...register("pori")}
                defaultValue={currentData.print.page.orientation}
                label={t('orientation')}
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('orientationAria') }}
                fullWidth
                onChange={(event) => setPoriVal(event.target.value)}>
                {Object.keys(pageOrientations).map((key) =>
                  <MenuItem key={key} value={key}>
                    {pageOrientations[key].label}
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            {spsVal === null &&
            <React.Fragment>
              <Grid item xs={2}>
                <TextField
                  {...register("pswidth", { value: currentData.print.page.customWidth, required: true, min: 1, max: 10000 })}
                  label={t('width')}
                  type="number"
                  inputProps={{ autoComplete: 'new-password', 'aria-label': t('widthAria') }}
                  fullWidth />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  {...register("psheight", { value: currentData.print.page.customHeight, required: true, min: 1, max: 10000 })}
                  label={t('height')}
                  type="number"
                  inputProps={{ autoComplete: 'new-password', 'aria-label': t('heightAria') }}
                  fullWidth />
              </Grid>
            </React.Fragment>
            }
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={3}>
              <FormControlLabel
                control={<Checkbox color="primary" name="margin" checked={margin} onChange={(event) => setMargin(event.target.checked)} />}
                label={<Typography variant="span" sx={{ fontSize: '0.95em' }}>{t('margin')}</Typography>}
                labelPlacement="start"
                sx={{ marginLeft: 0, my: 1 }}
                aria-label={t('marginAria')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("pmleft", { value: currentData.print.margin.left, required: margin, min: 0, max: 10000 })}
                label={t('left')}
                type="number"
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('leftAria') }}
                fullWidth
                disabled={!margin} />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("pmtop", { value: currentData.print.margin.top, required: margin, min: 0, max: 10000 })}
                label={t('top')}
                type="number"
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('topAria') }}
                fullWidth
                disabled={!margin} />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("pmright", { value: currentData.print.margin.right, required: margin, min: 0, max: 10000 })}
                label={t('right')}
                type="number"
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('rightAria') }}
                fullWidth
                disabled={!margin} />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("pmbottom", { value: currentData.print.margin.bottom, required: margin, min: 0, max: 10000 })}
                label={t('bottom')}
                type="number"
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('bottomAria') }}
                fullWidth
                disabled={!margin} />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={1}>
            <Grid item xs={4} sm={3}>
              <p>{t('grid')}</p>
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("rccols", { value: currentData.print.grid.cols, required: true, min: 1, max: 100 })}
                label={t('columns')}
                type="number"
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('columnsAria') }}
                fullWidth />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("rcrows", { value: currentData.print.grid.rows, required: true, min: 1, max: 100 })}
                label={t('rows')}
                type="number"
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('rowsAria') }}
                fullWidth />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("sphoriz", { value: currentData.print.grid.colGap, required: true, min: 0, max: 1000 })}
                label={t('colGap')}
                type="number"
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('colGapAria') }}
                fullWidth />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("spvertic", { value: currentData.print.grid.rowGap, required: true, min: 0, max: 1000 })}
                label={t('rowGap')}
                type="number"
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('rowGapAria') }}
                fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={3}>
              <p>{t('label')}</p>
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...register("spinner", { value: currentData.print.grid.itemPadding, required: true, min: 1, max: 100 })}
                label={t('padding')}
                type="number"
                inputProps={{ autoComplete: 'new-password', 'aria-label': t('paddingAria') }}
                fullWidth />
            </Grid>
          </Grid>
        </MyPaper>
        <Button type="submit" size="large" sx={{ my: 3 }} variant="contained" color="primary" endIcon={<PrintIcon />} aria-label={t('makeAria')} disabled={items.length <= 0}>{t('make')}</Button>
      </form>
      <h2>{t('next')}</h2>
      <Trans i18nKey="nextContent"></Trans>
      <br />
      <h3>{t('feedback')}</h3>
      <Trans i18nKey="feedbackContent"><p>Please leave us a <a href="mailto:contact@printplantpass.com?subject=Feedback" target="_blank" rel="noopener noreferrer">feedback</a>, help us <b>fit this page to your need</b>.</p></Trans>
      <br />
      <Header3>
        <a href="/#" id={t('routes:authority')}>&nbsp;</a>
        {t('authority')}
        <Link aria-label={t('authorityAria')} aria-hidden="true" href={`#${t('routes:authority')}`}><LinkIcon /></Link>
      </Header3>
      <ul>
        <li>Deutschland - <a href="https://verwaltung.bund.de/leistungsverzeichnis/DE/suche?query=pflanzenpass" target="_blank" rel="noreferrer">Bundesportal</a></li>
        <li>España - <a href="https://www.mapa.gob.es/es/agricultura/temas/sanidad-vegetal/pasaporte-fitosanitario" target="_blank" rel="noreferrer">Ministerio de Agricultura, Pesca, y Alimentación</a></li>
        <li>France - <a href="https://agriculture.gouv.fr/sante-des-vegetaux-un-nouveau-cadre-reglementaire-evolution-des-obligations-pour-les-professionnels" target="_blank" rel="noreferrer">Ministère de l'agriculture</a></li>
        <li>Ireland - <a href="https://www.gov.ie/en/publication/8e660-plant-passports" target="_blank" rel="noreferrer">Department of Agriculture, Food and the Marine</a></li>
        <li>Nederlands - <a href="https://business.gov.nl/regulation/plant-passport" target="_blank" rel="noreferrer">business.gov.nl</a></li>
      </ul>
      <br />
      <h3>{t('credits')}</h3>
      <Trans i18nKey="creditsContent"><cite>Botanical names are taken from the <a href="https://www.ipni.org/" target="_blank" rel="noopener noreferrer" aria-label="International Plant Names Index">IPNI (International Plant Names Index)</a>, thanks to The Royal Botanic Gardens, Kew, Harvard University Herbaria & Libraries and Australian National Botanic Gardens.</cite></Trans>
      <Footer>&copy;&nbsp;Copyright&nbsp;Print&nbsp;Plant&nbsp;Pass&nbsp;-&nbsp;2020-{new Date().getFullYear()}</Footer>
    </Root>
    </ThemeProvider>
    </Suspense>
  );
}

export default App;
