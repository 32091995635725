import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { CookiesProvider } from 'react-cookie';
import App from './App';

i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: process.env.NODE_ENV === "development",
        fallbackLng: 'en',
        supportedLngs: ['de', 'en', 'es', 'fr', 'it', 'nl'],
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        returnObjects: true,
        ns: ['app', 'index', 'routes'],
        defaultNS: 'app',
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
            checkWhitelist: true
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'em', 'b']
        }
    }, () => {
      ReactDOM.render(
        <CookiesProvider>
          <App />
        </CookiesProvider>,
        document.getElementById('root'),
        () => {
          const canonical = {
            de: 'https://www.printplantpass.com/de',
            en: 'https://www.printplantpass.com',
            es: 'https://www.printplantpass.com/es',
            fr: 'https://www.printplantpass.com/fr',
            it: 'https://www.printplantpass.com/it',
            nl: 'https://www.printplantpass.com/nl'
          };
          // html lang
          document.documentElement.setAttribute('lang', i18n.language);
          // head title
          document.title = i18n.t('index:title');
          // meta description
          document.querySelector('meta[name="description"]').setAttribute("content", i18n.t('index:description'));
          // link canonical
          const clink = document.querySelector('link[rel="canonical"]');
          clink.setAttribute("href", canonical[i18n.language]);
          // link alternate
          function insertAfter(newNode, referenceNode) {
            referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
          }
          Object.keys(canonical).forEach((key) => {
            if (key !== i18n.language) {
              const link = document.createElement('link');
              link.setAttribute("rel", "alternate");
              link.setAttribute("hreflang", key);
              link.setAttribute("href", canonical[key]);
              insertAfter(link, clink);
            }
          });
          // howto application/ld+json
          const howto = document.querySelector('script[id="howto"]');
          howto.innerText = JSON.stringify(i18n.t('index:howto'));
        }
      );
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
